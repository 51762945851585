import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Program from "./pages/Program";
import Artists from "./pages/Artists";
import Team from "./pages/Team";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="About" element={<About />} />
        <Route path="Program" element={<Program />} />
        <Route path="Artists" element={<Artists />} />
        <Route path="Team" element={<Team />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
