import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import React, { useState, useEffect } from 'react';

export default function About(){

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },[])

    document.title = "ABOUT - ÄÄNIAALTO VIII";

    const [toggle, setToggle] = useState(false);
    const [icon, setIcon] = useState(true);

    const handleMenu = () => {
        setToggle(!toggle)
        setIcon(!icon)
      }

    return(
        <div className="about-body">
            <Menu />
            <div className="burger-icon" onClick={() => {handleMenu()}}><h1>{icon ? `≡` : `x`}</h1></div>
            <MenuMobile toggle={toggle}/>

            <div className="about-text">
            <h1 className="about-title">ÄÄNI<br className="mobile-break"/>AALTO VIII</h1>
            <p className="about-p">
                <b>15 & 16 September, 18-22h at VTT</b> <br/>
                (Vapaan Taiteen Tila, Vilhonvuorenkuja 15-16, 00500 Helsinki)
            </p><p className="about-p">
                <b>23 & 24 September, 16-19h at Oodi </b> <br/>
                (Oodi Maijansali, Töölönlahdenkatu 4, 00100 Helsinki)
            </p>
            <p className="about-p">
                This year’s festival edition has a tendency to push the interdisciplinarity that emerges within artworks existing between artistic genres 
                and to explore various forms of performative multimedia and intermedia art. Join us to generate ecotones triggering the fluid dialectic and 
                the interplay between media.</p>

            <p className="about-p">
                ÄÄNIAALTO is an annual sound oriented new media festival. ÄÄNIAALTO spans into multiple days and venues presenting striking sonic oddities 
                in the form of performances, installations, workshops and open lectures. Festival has become a linking platform between emerging promises 
                in the field and established artists. The aim of ÄÄNIAALTO is to create a fluent network in the sonic new media scene in Helsinki and an 
                open culture accessible to anybody interested in learning and engaging with the new media’s practices.</p>

            <p className="about-p">
                ÄÄNIAALTO VIII - ECOTONAL SONICS - calls for interdisciplinary artworks, open lectures and hands-on workshops including topics of robotic 
                instruments, generative systems, DIY instruments, nonlinear narratives in sound performance, live coding, multichannel audio installations, 
                and the intersection between biology and sound among other explorations. Find new ways of connecting to the theme and challenge the concept 
                of a performance. How to blur the line between the artist and the audience? How to use sound as an ecological medium? How to disrupt existing program formats?</p>
            </div>
        </div>
    )
}