import React, { useState } from 'react';
import JsonData from './/program.json';

export default function ArtistsList({sortAZ}){

    const [play, setPlay] = useState(true)

    const DataDisplayTime = JsonData
        .sort((c,d) => { if (c.time < d.time){return -1}})
        .sort((a,b) => { if(a.day < b.day) {return -1}})
        .map(
        (info)=>{  
            const sound = info.sound;
            return (
            <div className="artist-card-one">
                <div className="artists-imgcont"><img className="artists-img" src={info.image} /></div>
                <div className="artists-info">
                    <h3>{info.artist} </h3>
                    <div className="artists-performance">  
                        {info.title} 
                        <audio id={info.id}>
                            <source src={info.sound}/>
                        </audio>
                        { sound !== "" &&
                        <span>  
                        <span className="top-menu-item" onClick={ () => {document.getElementById(info.id).play();}}>&#9658;&#xFE0E;</span>
                        <span className="top-menu-item" onClick={ () => {document.getElementById(info.id).load(); document.getElementById(info.id).currentTime = 0; }}>&#9209;&#xFE0E;</span>
                         </span> }
                    </div>
                    <div>{info.bio}</div>
                </div>
                <div className="artists-time">
                    
                    <h4>{info.day}</h4>
                    <h4>{info.time}</h4>
                    
                </div>
            </div>
            )
        }
    )

    const DataDisplayAZ = JsonData
        .sort((a,b) => { if(a.artist.toLowerCase() < b.artist.toLowerCase()){ return -1}})
        .map(
        (info)=>{
            const sound = info.sound;
            return(
            <div className="artist-card-one">
                <div className="artists-imgcont"><img className="artists-img" src={info.image} /></div>
                <div className="artists-info">
                    <h3>{info.artist} </h3>
                    <div className="artists-performance">  
                        {info.title} 
                        <audio id={info.id}>
                            <source src={info.sound}/>
                        </audio>
                        { sound !== "" &&
                        <span>  
                        <span className="top-menu-item" onClick={ () => {document.getElementById(info.id).play();}}>&#9658;</span>
                        <span className="top-menu-item" onClick={ () => {document.getElementById(info.id).load(); document.getElementById(info.id).currentTime = 0; }}>&#9209;</span>
                         </span> }
                    </div>
                    <div>{info.bio}</div>
                </div>
                <div className="artists-time">
                    
                    <h4>{info.day}</h4>
                    <h4>{info.time}</h4>
                    
                </div>
            </div>
            )
        }
    )

    

    return(
        <div className="artists-list">
            { sortAZ ? 
            <table class="artists-card">
                {DataDisplayAZ}
            </table>
            :
            <table class="artists-card">
                {DataDisplayTime}
            </table>
        }   
        </div>
    )
}