import React from 'react';
import JsonData from ".//team.json";

export default function TeamList(){

    const DataDisplay = JsonData.map(
        (info)=>{
            return(
                <div className="team-card">
                    <div className="team-imgcont"><img className="team-img" src={info.img}/></div>
                    <div className="team-name">{info.name}</div>
                    <div className="team-respons">{info.respons}</div>
                </div>
            )
        }
    )

    return(
        <div className="team-list">
            
                {DataDisplay}
            
        </div>
    )
}