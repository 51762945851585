import Menu from "../components/Menu"
import ProgramDay from "../components/ProgramDay"
import MenuMobile from "../components/MenuMobile"
import React, { useState, useEffect } from 'react';

export default function Program(){

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },[])

    document.title = "PROGRAM - ÄÄNIAALTO VIII";

    const [toggle, setToggle] = useState(false);
    const [icon, setIcon] = useState(true);
    const [bio, setBio] = useState(false);

    const handleMenu = () => {
        setToggle(!toggle)
        setIcon(!icon)
      }

    return(
        <div className="program-page">
            <Menu />
            <div className="burger-icon" onClick={() => {handleMenu()}}><h1>{icon ? `≡` : `x`}</h1></div>
            <MenuMobile toggle={toggle}/>

            <div className="program-body">
            <h1 className="program-title">PROGRAM</h1>
            <div className="program-bio top-menu-item" onClick={() => setBio(!bio)}>SHOW/HIDE BIOS</div>
                <div className="program-container">
                    <div className="program-day"><ProgramDay bio={bio}/>
                    </div>
                </div>
            </div>

        </div>
    )

}