import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from 'react'

export default function Home(){

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },[])

    document.title = "ÄÄNIAALTO VIII";

    const navigate = useNavigate();

    return (

       
        <div className="body-home">

            <div className="aa-home">
                <div className="link-home" onClick={() => {navigate('/')}}><h3>Ä</h3></div>
            </div>

            <div className="menu-home">
                <div className="link-home" onClick={() => {navigate('/About')}}><h2>ABOUT</h2></div>
                <div className="link-home" onClick={() => {navigate('/Program')}}><h2>PROGRAM</h2></div>
                <div className="link-home" onClick={() => {navigate('/Artists')}}><h2>ARTISTS</h2></div>
                <div className="link-home" onClick={() => {navigate('/Team')}}><h2>TEAM</h2></div>
            </div>

            <div className="title-home">
                <div><h1 className="home-title">ÄÄNI<br className="mobile-break"/>AALTO</h1></div>
                {/* <h1 className="home-title">{text}</h1> */}
                <div><h2 className="home-date">15/16/23/24<br />
                    SEPTEMBER</h2></div>
            </div>

        </div>
    )
}