import React, { useState } from 'react';
import JsonData from "./program.json";

export default function ProgramDay({bio}){

    const titleStyle = {
        fontFamily: 'lucette', fontSize: '1.3rem', lineHeight: '1rem', margin: '5px 0 6px 0', padding: '0', display: "inline-block"
    }
    const artistStyle = {
        margin: '0 0 0px 0', display: "inline-block"
    }
    const typeStyle = {
        fontStyle: 'italic', fontSize: '0.8rem', lineHeight: '0.8rem', display: "inline-block", marginTop: "5px"
    }
    const bioStyle = { 
        marginTop: '10px'
    }


    const DataSat1= JsonData
        .filter( (info) => info.day.includes("15"))
        .sort((a,b) => { if(a.time < b.time){return -1}})
        .map(
        (info) => {
            return(
            <tr>
                     <td className="program-time">{info.time}</td>
                     <td className="program-info">
                         <div style={titleStyle}> {info.title} </div><br />
                         <div style={artistStyle}>{info.artist}</div> <br />
                         <div style={typeStyle}>{info.type}</div>
                         { bio ? 
                        <div style={bioStyle}>{info.bio}</div>
                         : <></> } </td>
                 </tr>
            )
        }
        )
    const DataSun1=JsonData
    .filter( (info) => info.day.includes("16"))
    .sort((a,b) => { if(a.time < b.time){return -1}})
    .map(
    (info) => {
        return(
        <tr>
                 <td className="program-time">{info.time}</td>
                 <td className="program-info">
                     <div style={titleStyle}> {info.title} </div><br />
                     <div style={artistStyle}>{info.artist}</div> <br />
                     <div style={typeStyle}>{info.type}</div>
                     { bio ? 
                     <div style={bioStyle}>{info.bio}</div>
                     : <></> } </td>
             </tr>
        )
    }
    )
    const DataSat2=JsonData
    .filter( (info) => info.day.includes("23"))
    .sort((a,b) => { if(a.time < b.time){return -1}})
    .map(
    (info) => {
        return(
        <tr>
                 <td className="program-time">{info.time}</td>
                 <td className="program-info">
                     <div style={titleStyle}> {info.title} </div><br />
                     <div style={artistStyle}>{info.artist}</div> <br />
                     <div style={typeStyle}>{info.type}</div>
                     { bio ? 
                     <div style={bioStyle}>{info.bio}</div>
                     : <></> } </td>
             </tr>
        )
    }
    )
    
    const DataSun2=JsonData
    .filter( (info) => info.day.includes("24"))
    .sort((a,b) => { if(a.time < b.time){return -1}})
    .map(
    (info) => {
        return(
        <>
        <tr onClick={() => {console.log(info.id)}}>
                 <td className="program-time">{info.time}</td>
                 <td className="program-info">
                     <div style={titleStyle}> {info.title} </div><br />
                     <div style={artistStyle}>{info.artist}</div> <br />
                     <div style={typeStyle}>{info.type}</div>
                     { bio ? 
                     <div style={bioStyle}>{info.bio}</div>
                     : <></> } </td>
        </tr>
        </>
        )
    }
    )


    return(
        
        <div className="program-tables">
            <div>
                <h3 className="program-title">15 SEPT</h3>
                <p className="program-location">@ VTT</p>
                <table class="program-table">
                {DataSat1}
            </table></div>
            <div>
                <h3 className="program-title">16 SEPT</h3>
                <p className="program-location">@ VTT</p>
                <table class="program-table">
                {DataSun1}
            </table></div>
            <div>
                <h3 className="program-title">23 SEPT</h3>
                <p className="program-location">@ Oodi</p>
                <table class="program-table">
                {DataSat2}
            </table></div>
            <div>
                <h3 className="program-title">24 SEPT</h3>
                <p className="program-location">@ Oodi</p>
                <table class="program-table">
                {DataSun2}
            </table></div>
        </div>
        
    )
}