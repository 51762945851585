import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function Menu(){

    const navigate = useNavigate();

    return(


    <div className="top-menu">
        <div className="top-menu-item" onClick={() => {navigate('/')}}><h3>Ä</h3></div>
        <div className="top-menu-item" onClick={() => {navigate('/About')}}><h3>ABOUT</h3></div>
        <div className="top-menu-item" onClick={() => {navigate('/Program')}}><h3>PROGRAM</h3></div>
        <div className="top-menu-item" onClick={() => {navigate('/Artists')}}><h3>ARTISTS</h3></div>
        <div className="top-menu-item" onClick={() => {navigate('/Team')}}><h3>TEAM</h3></div>
    </div>

    )
}