import Menu from "../components/Menu"
import MenuMobile from "../components/MenuMobile"
import React, { useState, useEffect } from 'react';
import TeamList from "../components/TeamList"


export default function Team(){

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },[])

    document.title = "TEAM - ÄÄNIAALTO VIII";

    const [toggle, setToggle] = useState(false);
    const [icon, setIcon] = useState(true);

    const handleMenu = () => {
        setToggle(!toggle)
        setIcon(!icon)
      }

    return(
        <div>
            <Menu/>
            <div className="burger-icon" onClick={() => {handleMenu()}}><h1>{icon ? `≡` : `x`}</h1></div>
            <MenuMobile toggle={toggle}/>
            

            <div className="team-body">
                <div>
                    <TeamList />
                </div>
                <div className="team-text">
                    <h1 className="team-title">TEAM</h1>
                    <p>For the first time this year, Ääniaalto has been fully organized by a seperate committee established under DADA. Volunteers were selected through an open call, resulting in the creation of our multi-disciplinary production team. Although born out of Aalto MediaLab, this year Ääniaalto became a bridge that brings together visions from different institutions. With members from Aalto ARTS, Sibelius Academy, Aalto BIZ and University of Lapland; we aimed to create a festival that reaches beyond Aalto. </p>
                    <p>The production team has worked for over 6 months to bring you Ääniaalto VIII. From the artistic curation to media outreach, sound & stage design to visual identitity; all aspects of this event has been crafted over these months to bring a one-of-a-kind experience to our viewers. For this, DADA would like to express endless gratitude to the production team, and everyone that worked on Ääniaalto VIII.</p>
                    <p>Don't hesitate to contact us with any questions. 
                        You can reach us through <a href="https://www.instagram.com/aaniaalto_fluxisland/" target="_blank">instagram</a> or via <a href="mailto:hamidreza.keshvarpajuh@uniarts.fi">e-mail</a>.</p>
                    <p>Ääniaalto is funded and supported by <a href="https://www.instagram.com/dada_mlab/" target="_blank">DADA</a>, the Digital Art and Media Association of Aalto University.</p>
                </div>
            </div>
        </div>
    )

}