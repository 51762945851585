import Menu from "../components/Menu"
import ArtistsList from "../components/ArtistsList"
import MenuMobile from "../components/MenuMobile"
import React, { useState, useEffect } from 'react';

export default function Artists(){

    useEffect(() => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
      },[])

    document.title = "ARTISTS - ÄÄNIAALTO VIII";

    const [toggle, setToggle] = useState(false);
    const [icon, setIcon] = useState(true);

    const handleMenu = () => {
        setToggle(!toggle)
        setIcon(!icon)
      }

    const [sortAZ, setAZ] = useState(false);


    return(
        <div className="artists-page">
            <Menu />
            <div className="burger-icon" onClick={() => {handleMenu()}}><h1>{icon ? `≡` : `x`}</h1></div>
            <MenuMobile toggle={toggle}/>

            <div className="artists-body">
            <h1 className="artists-title">ARTISTS</h1>
            
            <div className="artists-sort">
                <div className="top-menu-item" onClick = { () => { setAZ(false)}}>CHRONOLOGICALLY</div>
                <div className="top-menu-item" onClick = { () => { setAZ(true)}}>A-Z</div>
            </div>
            <ArtistsList sortAZ={sortAZ}/>
            </div>

        </div>
    )
}