import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function MenuMobile({toggle}){

    const navigate = useNavigate();

    return(
        <div>
            
    { toggle ? 
    <div className="burger-menu">
    <div className="burger-menu-item" onClick={() => {navigate('/')}}><h2>Ä</h2></div>
        <div className="burger-menu-item" onClick={() => {navigate('/About')}}><h2>ABOUT</h2></div>
        <div className="burger-menu-item" onClick={() => {navigate('/Program')}}><h2>PROGRAM</h2></div>
        <div className="burger-menu-item" onClick={() => {navigate('/Artists')}}><h2>ARTISTS</h2></div>
        <div className="burger-menu-item" onClick={() => {navigate('/Team')}}><h2>TEAM</h2></div>
    </div>
    : "" }
    </div>
    )
}